<template>
  <div class="px-3 mt-5">
    <form class="row order">
      <div class="col-sm-12 text-start">
        <div class="row mb-4">
          <div class="form-group col-md-6 mb-2">
            <label for="merchantTradeNo" class="text-muted mb-2">訂單編號</label>
              <input type="text" class="form-control" id="merchantTradeNo"
                v-model="order.merchantTradeNo"
                placeholder="訂單編號"
                readonly>
          </div>
          <div class="form-group col-md-6 mb-2">
            <label for="merchantDate" class="text-muted mb-2">訂單建立時間</label>
            <input type="text" class="form-control" id="merchantDate"
              v-model="merchantDate"
              placeholder="訂單建立時間"
              readonly>
          </div>
        </div>
        <div class="row mb-4">
          <div class="form-group col-md-6 mb-2">
            <label for="totalAmount" class="text-muted mb-2">金額</label>
              <input type="text" class="form-control" id="totalAmount"
                v-model="order.totalAmount"
                readonly
              >
          </div>
          <div class="form-group col-md-6 mb-2">
            <label for="choosePayment" class="text-muted mb-2">付款方式</label>
              <input type="text" class="form-control" id="choosePayment"
                v-model="order.choosePayment"
                placeholder="付款方式"
                readonly
              >
          </div>
        </div>
        <div class="row mb-4">
          <div class="form-group col-md-6 mb-2">
            <label for="status" class="text-muted mb-2">訂單狀態</label>
            <select class="form-select" aria-label="courses__author" v-model="order.status">
                <!-- <option :value="item.id" v-for="item in teacherList" :key="item.id">{{ item.userName }}</option> -->
                <option disabled value>訂單狀態</option>
                <option :value="item.id" v-for="item in statusList" :key="item.id">{{ item.name }}</option>
                <option value="手動訂單">手動訂單</option>
            </select>
          </div>

          <div class="form-group col-md-6 mb-2 text-end d-flex align-items-end justify-content-end">
            <button class="btn btn-main" type="submit">儲存</button>
          </div>
        </div>
        <div class="row mb-4">
          <h2 class="order__coursetitle mb-2">學生列表</h2>
          <div class="card border-0 text-start mb-4 py-2 col-12">
            <div class="card-body p-4 py-2 order__item" v-for="item in order.users" :key="item.id">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="my-1" style="font-size: 1rem;">學生名稱：{{ item.name }}</h5>
                <span><i class="bi bi-clipboard clipboard-tooltip" @click="copyUrl(item.id)" style="cursor: pointer;"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <h2 class="order__coursetitle mb-2">品項列表</h2>
          <div class="card border-0 text-start mb-4 col-12">
            <div class="card-body p-4 order__item" v-for="item in order.items" :key="item.courseId">
              <h5 class="card-title mb-4 order__coursetitle">名稱：{{ item.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">課程售價</h6>
              <p class="card-text mb-4 order__price">$ {{ item.price }}</p>
              <p class="mb-2">數量：{{ item.amount }}</p>
              <p class="mb-2" v-if='item.remark'>備註：{{ item.remark }}</p>
            </div>
          </div>
        </div>
      </div>
    </form>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Order',
  data() {
    return {
      oid: '',
      merchantDate: '',
      statusList: [],
      order: {
        id: '',
        merchantTradeNo: '',
        userId: '',
        merchantDate: '',
        totalAmount: '',
        choosePayment: '',
        status: 1,
        invoice: null,
        items: [],
        users: [],
      },
      metaTitle: '訂單資訊 / 訂單 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "訂單資訊 / 訂單 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '訂單資訊 / 訂單 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      this.$http.get(`${this.$API_PATH}/Admin/Order/Status`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.statusList = res.data.data.status;
        return this.$http.get(`${this.$API_PATH}/Admin/Order/Management/${this.oid}`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        // console.log(res.data.data.order);
        this.order = res.data.data.order;
        this.statusList.forEach((item) => {
          if (item.name == this.order.status) {
            this.order.status = item.id;
          }
        });

        this.merchantDate = this.order.merchantDate.split('T').join(' ');
      }).catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    copyUrl(id) {
      const el = document.createElement('textarea');
      el.value = id;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.toast();
      // console.log(this.walletAddress);
    },
    toast() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: '複製完成',
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.oid = this.$route.params.oid;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .order {
    &__item {
      border-bottom: 1px solid rgb(219, 219, 219);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  .clipboard-tooltip {
    position: relative;
    &::after {
      position: absolute;
      content: '複製學生ID';
      top: -1.5rem;
      text-align: center;
      left: -2rem;
      width: 6rem;
      height: 1rem;
      font-size: 14px;
      background: #ffffff;
      color: black;
      z-index: 3;
      border-radius: 5px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    &:hover.clipboard-tooltip::after {
      opacity: 1;
    }
  }
</style>
